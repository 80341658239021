import React from 'react'
import store from 'stores/store'
import styles from './Imprint.module.scss'

const Imprint = () => {
  return (
    <div className={styles.wrapper}>
      <section className={styles.row}>
        <div>
          <h1 className={styles.header}>
            Impressum
          </h1>
          <p className={styles.update}>
            Letztes Update: {store.imprint.last_update}
          </p>
        </div>
      </section>

      {/* Imprint Text */}
      <section className={styles.content}>
        {store.imprint.content}
      </section>

    </div>
  )
}

export { Imprint as default }