import { Loading, Modal } from "components"
import React, { Suspense, lazy } from "react"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import { inject, observer } from "mobx-react"

import { ApolloProvider } from "react-apollo"
import { Home } from "screens"
import client from "graphql/client.js"
import { history } from "utils"
import styles from "./App.module.scss"

// Custom Routes
const Custom01 = lazy(() => import("screens/Custom_01/Custom_01"))
const Custom02 = lazy(() => import("screens/Custom_02/Custom_02"))

// Fixed Routes
const Contact = lazy(() => import("screens/Contact/Contact"))
const Imprint = lazy(() => import("screens/Imprint/Imprint"))
const CookiePolicy = lazy(() => import("screens/CookiePolicy/CookiePolicy"))
const PrivacyPolicy = lazy(() => import("screens/PrivacyPolicy/PrivacyPolicy"))

const _lazyload = (Component) => {
  return (props) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  )
}

const App = () => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <div className={styles.background}>
          <Modal visible={modalShow} onClick={() => setModalShow(false)} />

          <Switch>
            {/* Custom Routes */}
            <Route exact path="/" component={Home} />
            <Route path="/produkte" component={_lazyload(Custom01)} />
            <Route path="/mitgliedschaft" component={_lazyload(Custom02)} />

            {/* General Routes */}
            <Route path="/öffnungszeiten" component={_lazyload(Contact)} />

            {/* Legal Routes */}
            <Route path="/impressum" component={_lazyload(Imprint)} />
            <Route path="/datenschutz" component={_lazyload(PrivacyPolicy)} />
            <Route path="/cookies" component={_lazyload(CookiePolicy)} />

            {/* Redirect */}
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  )
}

export default inject("store")(observer(App))
