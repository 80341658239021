import { Button, Icon, Logo, MobileMenu } from "components"
import { Link, NavLink } from "react-router-dom"
import React, { useState } from "react"
import { buttons, navLinks, type } from "assets/config/header"

import MediaQuery from "react-responsive"
import { responsive } from "utils"
import styles from "./Header.module.scss"

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const scroll = () => {
    setTimeout(function () {
      document
        .getElementById("anfahrt")
        .scrollIntoView({ behavior: "smooth", block: "start" })
    }, 200)
  }

  const _renderLogo = () => (
    <Link to="/" className={styles.logo}>
      <Logo />
    </Link>
  )

  const _renderNavLinks = () =>
    navLinks.map((navLink) => (
      <NavLink
        to={navLink.to}
        className={styles.navLink}
        activeClassName={styles.navLink_active}
        key={navLink.name}
      >
        {navLink.name}
      </NavLink>
    ))

  const _renderButtons = () =>
    buttons.length > 0
      ? buttons.map((button) => (
          <NavLink to={button.to} className={styles.navLink} key={button.name}>
            <Button to={button.to} size="large" className={styles.button}>
              {button.name}
            </Button>
          </NavLink>
        ))
      : null

  const _renderMenuBars = () => (
    <div
      className={styles.menuBars}
      onClick={() => setShowMobileMenu(!showMobileMenu)}
    >
      <Icon
        icon={!showMobileMenu ? ["fas", "bars"] : ["fas", "times"]}
        className={styles.menuBars_icon}
      />
    </div>
  )

  return (
    <header
      className={` 
        ${styles.background}
        ${type === "fixed" ? styles.fixed : ""}
      `}
    >
      {/* Desktop Header */}
      <MediaQuery minWidth={responsive.minDeviceWidthLG}>
        <div
          className={!showMobileMenu ? styles.wrapper : styles.wrapper_active}
        >
          {_renderLogo()}
          <nav>
            {_renderNavLinks()}
            <NavLink
              className={styles.navLink}
              activeClassName={styles.navLink_active}
              to="/#anfahrt"
              onClick={scroll}
            >
              Anfahrt
            </NavLink>
            {_renderButtons()}
          </nav>
        </div>
      </MediaQuery>

      {/* Mobile Header */}
      <MediaQuery maxWidth={responsive.maxDeviceWidthMD}>
        <div
          className={!showMobileMenu ? styles.wrapper : styles.wrapper_active}
        >
          {!showMobileMenu ? _renderLogo() : <div />}
          {_renderMenuBars()}
        </div>
      </MediaQuery>

      {/* Mobile Menu */}
      {showMobileMenu ? (
        <MobileMenu onClick={() => setShowMobileMenu(!showMobileMenu)} />
      ) : null}
    </header>
  )
}

export { Header as default }
