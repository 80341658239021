import React, {Fragment} from 'react'

import member_benefits from 'assets/images/member_benefits.png'
import member_trial from 'assets/images/member_trial.png'
import styles from './Custom_02.module.scss'

const Custom02 = () => {
  return (
    <Fragment>

      <section className={styles.hero} >
        <div className={styles.hero_inner}>
          <h1 className={styles.header}>Als Mitglied <br/> bis zu 15% sparen!</h1>
          <p className={styles.subheader}>Werden Sie Mitglied in unserer Einkaufsgemeinschaft und sparen Sie bis zu 15% auf ausgewählte Produkte.</p>
        </div>
      </section>

      <section className={styles.benefits}>
        <img className={styles.benefits_img} src={member_benefits} alt='Mitgliedschaft - Ihre Vorteile auf einen Blick' />
        <div className={styles.benefits_text}>
          <h2 className={styles.benefits_title}>Ihre Vorteile auf einen Blick!</h2>
          <p>
            Gemeinsam sind wir stark! Das gilt auch für unsere Einkaufsgemeinschaft von der auch Sie profitieren können. Durch Ihre Mitgliedschaft unterstützen Sie nämlich nicht nur die Naturecke selbst, sondern profitieren ebenso von deutlich geringeren Preisen. 
            <br/><br/>
            Mit bis zu 15% Rabatt auf unsere Produkte können Sie so jeden Monat bares Geld sparen, während Sie zudem zum Bestand der Naturecke in Haldenwang beitragen.
          </p>
          <p className={styles.benefits_highlighted}>
            Schauen Sie doch einfach einmal bei uns vorbei. <br/>
            Wir würden uns sehr freuen Sie bald bei uns im Laden begrüßen zu dürfen.
          </p>
        </div>
      </section>

      <section className={styles.test}>
        <div className={styles.test_text}>
          <h2 className={styles.test_title}>Probieren Sie es einfach aus!</h2>
          <p>
            Konnten wir Sie noch nicht ganz überzeugen? 
            <br/><br/>
            Dann kommen Sie doch einfach einmal bei uns vorbei und probieren unsere Mitgliedschaft vollkommen unverbindlich aus. Einen ganzen Monat lang! 
            <br/><br/>
            Natürlich stehen wir Ihnen, während unserer Öffnungszeiten, auch jederzeit telefonisch zur Verfügung und beantworten sehr gerne Ihre Fragen. 
          </p>
          <p className={styles.test_highlighted}>
            Sie erreichen uns unter 08374 / 586200
          </p>
        </div>
        <img className={styles.test_img} src={member_trial} alt='Mitgliedschaft - Probieren Sie es einfach aus!' />
      </section>

    </Fragment>
  )
}

export { Custom02 as default }