import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Custom02 as Custom02Container } from 'containers'
import { helmet } from 'utils'
import styles from './Custom_02.module.scss'

const SEO_Data = helmet({
  title: 'Mitgliedschaft',
  description: 'Werden Sie Mitglied in unserer Einkaufsgemeinschaft und sparen bis zu 15% auf ausgewählte Produkte.'
})

const Custom02 = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <Custom02Container />
      </article>
      <Footer />
    </div>
  )
}

export { Custom02 as default }