import React from 'react'
import styles from './Careers.module.scss'

const Careers = () => {
  return (
    <div className={styles.background}>

    </div>
  )
}

export { Careers as default }