// Basic Header Data 
//(Don't forget to adapt padding-top css variable)

const type = 'fixed'

// Navigation Links
const navLinks = [
  {
    name: 'Produkte',
    to: '/produkte'
  }, {
    name: 'Mitgliedschaft',
    to: '/mitgliedschaft'
  }
]

// Action Buttons
const buttons = [
  {
  name: 'Öffnungszeiten',
  to: '/öffnungszeiten'
  }
]

export {
  type,
  navLinks,
  buttons
}