import React, {Fragment}  from 'react'

import brot from 'assets/images/product_brot.png'
import cheese from 'assets/images/product_cheese.png'
import coffee from 'assets/images/product_coffee.png'
import nuts from 'assets/images/product_nuts.png'
import obst from 'assets/images/product_obst.png'
import oil from 'assets/images/product_oil.png'
import pasta from 'assets/images/product_pasta.png'
import styles from './Custom_01.module.scss'
import sweets from 'assets/images/product_sweets.png'
import wein from 'assets/images/product_wein.png'

const data = [{
  title: "Obst & Gemüse", 
  description: "Frisch, lecker und natürlich, so gut wie immer, aus der Region.", 
  picture: obst,
  alt: "Naturecke Haldenwang - Obst & Gemüse"
},{
  title: "Backwaren", 
  description: "Knusprig, körnig und täglich frisch von regionalen Bio Bäckereien.", 
  picture: brot,
  alt: "Naturecke Haldenwang - Allerlei Gebäck"
},{
  title: "Regionaler Käse", 
  description: "Ob kräftig oder mild, auf jeden Fall aus regionaler Produktion.", 
  picture: cheese,
  alt: "Naturecke Haldenwang - Regionaler Käse"
},{
  title: "(Bohnen)Kaffee", 
  description: "Genießen Sie Ihn direkt bei uns im Laden oder auch bei Ihnen Zuhause.", 
  picture: coffee,
  alt: "Naturecke Haldenwang - (Bohnen)Kaffee"
},{
  title: "Öl & Essig", 
  description: "Oliven-, Raps-, Sonnenblumenöl & mehr, sowie verschiedenste Essigsorten.", 
  picture: oil,
  alt: "Naturecke Haldenwang - Öl & Essig"
},{
  title: "Weine", 
  description: "Bei uns bekommen Sie die edelsten Tropfen von Bio Wein Grasser.", 
  picture: wein,
  alt: "Naturecke Haldenwang - Weine"
},{
  title: "Getreideprodukte", 
  description: "Pasta und Mehl in den verschiedensten Sorten & Ausführungen.", 
  picture: pasta,
  alt: "Naturecke Haldenwang - Getreideprodukte"
},{
  title: "Trockenfrüchte", 
  description: "Trockenfrüchte und Nüsse - Perfekt für den gesunden Snack zwischendurch.", 
  picture: nuts,
  alt: "Naturecke Haldenwang - Trockenfrüchte"
},{
  title: "Süßigkeiten", 
  description: "Süße Snacks, Bonbons, Frucht- und Müslischnitten & andere Knabbereien.", 
  picture: sweets,
  alt: "Naturecke Haldenwang - Süßigkeiten"
}]

const Custom01 = () => {
  return (
    <Fragment>

      <section >
        <h1 className={styles.title}>Stöbern Sie durch eine Auswahl unserer Produkte</h1>
      </section>

      <section className={styles.products}>
        {data.map(x => 
          <div className={styles.product}>
            <img src={x.picture} alt={x.alt} />
            <h3>{x.title}</h3>
            <p>{x.description}</p>
          </div>
        )}
      </section>

    </Fragment>
  )
}

export { Custom01 as default }