import React from 'react'
import styles from './Contact.module.scss'

const Contact = () => {
  return (
    <section className={styles.background}>
      <div className={styles.container}>

        <div className={styles.header}>
          <h1>Unsere Feinkaufzeiten</h1>
        </div>

        <div className={styles.hours}>
          <div className={styles.card}>
            <p className={styles.day}>Montag</p>
            <p>08:00 bis 13:00 Uhr <br/> 15:00 bis 18:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Dienstag</p>
            <p>08:00 bis 13:00 Uhr <br/> 15:00 bis 18:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Mittwoch</p>
            <p>08:00 bis 13:00 Uhr <br/> 15:00 bis 18:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Donnerstag</p>
            <p>08:00 bis 13:00 Uhr <br/> 15:00 bis 18:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Freitag</p>
            <p>08:00 bis 13:00 Uhr <br/> 15:00 bis 18:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Samstag</p>
            <p>07:30 bis 13:00 Uhr</p> 
          </div>
          <div className={styles.card}>
            <p className={styles.day}>Sonntag</p>
            <p>Geschlossen</p> 
          </div>
        </div>
        
      </div>
    </section>
  )
}

export { Contact as default }