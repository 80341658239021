import React from 'react'
import store from 'stores/store'
import styles from './PrivacyPolicy.module.scss'

const PrivacyPolicy = () => {
  return (
    <div className={styles.wrapper}>

      <section className={styles.row}>
        <div>
          <h1 className={styles.header}>
            Datenschutz
          </h1>
          <p className={styles.update}>
            Letztes Update: {store.privacy.last_update}
          </p>
        </div>
      </section>

      {/* Privacy Policy Text */}
      <section className={styles.content}>
        {store.privacy.content}
      </section>

    </div>
  )
}

export { PrivacyPolicy as default }