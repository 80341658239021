import { Button } from "components"
import ModalBS from "react-awesome-modal"
import React from "react"
import styles from "./Modal.module.scss"

const Modal = ({ visible, onClick, ...props }) => (
  <ModalBS
    visible={visible}
    className={styles.modal}
    effect="fadeInDown"
    onClickAway={onClick}
  >
    <div className={styles.wrapper}>
      <h2>Sommer Öffnungszeiten</h2>
      <p>
        <strong>Liebe Kunden,</strong>
        <br />
        <br />
        in den Wochen vom <strong>26.08.2024</strong> bis zum <br />
        <strong>28.09.2024</strong> gelten folgende Öffnungszeiten:
        <br />
        <br />
        Mo - Fr: 08:00 Uhr - 13:00 Uhr
        <br />
        Mo - Fr: 15:00 Uhr - 18:00 Uhr
        <br />
        <strong>Samstags geschlossen!</strong>
        <br />
        <br />
        vielen Dank für Ihr Verständnis!
        <br />
        <br />
      </p>
      <Button size="large" onClick={onClick}>
        Okay
      </Button>
    </div>
  </ModalBS>
)

export { Modal as default }
