import React from 'react'
import styles from './GoogleMaps.module.scss'

const GoogleMaps = ({
  place,
  wrapperClassName,
  className,
  ...rest
}) => (
  <section className={wrapperClassName}>
    <iframe 
      title='Location'
      className={`${styles.map} ${className}`}
      src={`https://www.google.com/maps/embed/v1/place?q=place_id:${place}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
      {...rest}
    />
  </section>
)

export { GoogleMaps as default }