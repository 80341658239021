import { Link } from "react-router-dom"
import React from "react"
import styles from "./Button.module.scss"

const Button = ({ type, rounded, square, size, to, className, ...props }) => {
  return (
    <Link
      to={to ? to : ""}
      className={`
        ${styles.general}
        ${type ? styles[type] : styles.primary}
        ${size ? styles[size] : styles.medium}
        ${styles.regular}
        ${rounded ? styles.rounded : ""}
        ${square ? styles.square : ""}
        ${className}
      `}
      {...props}
    >
      {props.children}
    </Link>
  )
}

export { Button as default }
