import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Home as HomeContainer } from 'containers'
import { helmet } from 'utils';
import styles from './Home.module.scss'

const SEO_Data = helmet({
  title: 'Naturecke Haldenwang',
  description: 'Naturkost für Leib und Seele. Bei uns erhalten Sie gesunde, leckere Lebensmittel aus der Region. Natürlich stets in Bio Qualität und einfach absolut lecker.'
})

const Home = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <HomeContainer />
      </article>
      <Footer />
    </div>
  )
}

export { Home as default }