import { Icon, Logo } from "components"

import { Link } from "react-router-dom"
import React from "react"
import styles from "./Footer_Centered.module.scss"

const FooterCentered = ({ copyright, description, legal, socialMedia }) => {
  const _renderSocialMedia = (socialMedia) => (
    <ul className={styles.social}>
      {socialMedia.map((x) => (
        <li className={styles.social_item}>
          <Link to={x.to} className={styles.social_link}>
            <Icon type={["fas", "heart"]} />
          </Link>
        </li>
      ))}
    </ul>
  )

  const _renderLegal = (legal) => (
    <ul className={styles.legal}>
      {legal.map((x) => (
        <li className={styles.legal_item} key={x.name}>
          <Link to={x.to} className={styles.legal_link}>
            {x.name}
          </Link>
        </li>
      ))}
    </ul>
  )

  const _renderCreatorInfo = () => (
    <div className={styles.creatorInfo}>
      <div>
        Made with{" "}
        <Icon icon={["fas", "heart"]} className={styles.creatorInfo_icon} /> by
        <a
          href={"https://www.weborilla.de"}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.creatorInfo_logo}
        >
          {" "}
          Weborilla{" "}
        </a>
      </div>
      <div className={styles.copyright}>
        <Icon type={["far", "copyright"]} className={styles.copyright_icon} />{" "}
        {copyright} - {new Date().getFullYear()}
      </div>
    </div>
  )

  return (
    <footer className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.company}>
          <Logo />
          <p className={styles.company_description}>{description}</p>
        </div>
        {socialMedia ? _renderSocialMedia(socialMedia) : null}
        {_renderLegal(legal)}
      </div>
      <hr className={styles.divider} />
      {_renderCreatorInfo()}
    </footer>
  )
}

export { FooterCentered as default }
