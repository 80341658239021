import { GoogleMaps, Modal } from "components"
import React, { Fragment } from "react"

import { Link } from "react-router-dom"
import brot from "assets/images/product_brot.png"
import home_history from "assets/images/home_history.png"
import kaese from "assets/images/product_cheese.png"
import obst from "assets/images/product_obst.png"
import rapunzel from "assets/images/rapunzel.png"
import styles from "./Home.module.scss"

const Home = () => {
  const [modalShow, setModalShow] = React.useState(true)

  return (
    <Fragment>
      <Modal show={modalShow} onHide={() => setModalShow(false)} />

      <section className={styles.hero}>
        <div className={styles.hero_inner}>
          <h1 className={styles.header}>
            Naturkost <br /> für Leib und Seele
          </h1>
          <p className={styles.subheader}>
            Bei uns erhalten Sie gesunde, leckere Lebensmittel aus der Region.
            Natürlich stets in Bio Qualität und einfach absolut lecker.
          </p>
        </div>
      </section>

      <section className={styles.history}>
        <img
          className={styles.history_img}
          src={home_history}
          alt="Mitgliedschaft - Ihre Vorteile auf einen Blick"
        />
        <div className={styles.history_text}>
          <h2 className={styles.history_title}>Die Geschichte der Naturecke</h2>
          <p>
            Im Jahre 1988 eröffneten Rita Schneid und Felicitas Schindler
            erstmals die Naturecke in Haldenwang, um frische, leckere Produkte
            aus der Region zu vertreiben.
            <br />
            <br />
            Heute ist Katharina Pleth die neue Inhaberin der Naturecke und führt
            die Vision der damaligen Gründerinnen nahezu unverändert fort. Neben
            zahlreichen Kooperationen mit regionalen Partnern wird daher ebenso
            ein sehr großer Wert auf qualitativ hochwertige sowie
            umweltfreundliche Lieferketten gelegt.
          </p>
          <p className={styles.history_highlighted}>
            Schauen Sie doch einfach einmal bei uns vorbei. <br />
            Wir würden uns sehr freuen Sie bald bei uns im Laden begrüßen zu
            dürfen.
          </p>
        </div>
      </section>

      <section className={styles.products}>
        <div className={styles.products_header}>
          <h2>Unsere Produkte</h2>
          <p>Natürlich immer frisch und stets äußerst lecker.</p>
        </div>

        <div className={styles.products_section1}>
          <div className={styles.products_box}>
            <p className={styles.products_slogan}>Gesund & Lecker</p>
            <h3>Frisches Obst und Gemüse</h3>
            <p>
              Ob rot, grün oder gelb, unser Obst und Gemüse ist nicht nur
              äußerst lecker, sondern auch immer frisch und soweit möglich
              direkt aus der Region.
              <br />
              <br />
              Äpfel, Birnen, Bananen, Orangen, Ananas, Zitronen, Tomaten,
              Gurken, Salate und vieles vieles mehr finden Sie bei uns an der
              Obst- und Gemüsetheke.
            </p>
          </div>
          <img
            className={styles.products_box}
            src={obst}
            alt="Naturecke Haldenwang - Obst und Gemüse"
          />
        </div>

        <div className={styles.products_section2}>
          <img
            className={styles.products_box}
            src={brot}
            alt="Naturecke Haldenwang - Obst und Gemüse"
          />
          <div className={styles.products_box}>
            <p className={styles.products_slogan}>Knusprig & Körnig</p>
            <h3>Leckerste Backwaren</h3>
            <p>
              Eine knusprige Scheibe Brot oder ein paar frische Semmel zum
              Frühstück? Wir führen die leckersten Backwaren folgender
              Bäckereien: <br />
              <br />
              <a
                href="https://www.vollwertbaeckerei-kornblume.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vollwertbäckerei Kornblume
              </a>
              <br />
              <a
                href="http://www.franzundxaver.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Franz & Xaver (Bio Lifestyle)
              </a>
              <br />
              <a
                href="https://www.reitberger.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bäckerei Reitberger
              </a>
              <br />
            </p>
          </div>
        </div>

        <div className={styles.products_section3}>
          <div className={styles.products_box}>
            <p className={styles.products_slogan}>Herzhaft & Regional</p>
            <h3>Käse aus der Region</h3>
            <p>
              Mild, deftig, nussig und noch so einige andere
              Geschmacksrichtungen finden sich in unserer Auswahl an Käsesorten
              aus der Region.
              <br />
              <br />
              Unter anderem beziehen wir die zahlreichen Käsespezialitäten aus
              der Käseküche Isny sowie der Schaukäserei Wiggensbach.
            </p>
          </div>
          <img
            className={styles.products_box}
            src={kaese}
            alt="Naturecke Haldenwang - Obst und Gemüse"
          />
        </div>

        <div className={styles.products_section4}>
          <div className={styles.products_boxend}>
            <h3>& Vieles, vieles mehr!</h3>
            <p>
              Natürlich finden Sie bei uns noch zahlreiche weitere
              Köstlichkeiten. Neben ausgewählten Weinen von Bio Wein Grass
              bieten wir Ihnen ebenso eine große Auswahl an Getreideprodukten,
              Trockenfrüchten, Kaffee, Öl und Essig sowie einigen gesunden
              Snacks für den kleinen Hunger zwischendurch.
            </p>
            <br />
            <Link className={styles.products_boxend_link} to="/produkte">
              Zu unserem Sortiment
            </Link>
          </div>
        </div>
      </section>

      <section>
        <h2 id="anfahrt" className={styles.title}>
          Sie finden uns in Haldenwang im Allgäu
        </h2>
      </section>
      <GoogleMaps place="ChIJTyg_fhp3nEcRsZpQAeuOhCI" />

      <section className={styles.partner}>
        <h3>Wir sind stolzer Rapunzel Partner</h3>
        <a
          href="https://www.rapunzel.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={rapunzel}
            alt="Naturecke Haldenwang - Stolzer Rapunzel Partner"
          />
        </a>
      </section>
    </Fragment>
  )
}

export { Home as default }
