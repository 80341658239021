import { buttons, navLinks } from 'assets/config/header'

import { Logo } from 'components'
import { NavLink } from 'react-router-dom'
import React from 'react'
import styles from './MobileMenu.module.scss'

const MobileMenu = ({
  onClick
}) => {

  const scroll = () => {
    setTimeout(function(){  
      document.getElementById("anfahrt").scrollIntoView({behavior: 'smooth', block: 'start'})
    }, 200);
  }

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <NavLink to='/' onClick={onClick}>
          <Logo />
        </NavLink>
        { 
          navLinks.map(navLink => (
            <NavLink 
              to={navLink.to} 
              className={styles.navLink}
              activeClassName={styles.navLink_active}
              onClick={onClick}
            >
              {navLink.name}
            </NavLink>
          ))
        }
        <NavLink className={styles.navLink} activeClassName={styles.navLink_active} to="/#anfahrt" onClick={scroll}>Anfahrt</NavLink>
        { 
          buttons.map(button => (
            <NavLink 
              to={button.to} 
              className={styles.navLink}
              activeClassName={styles.navLink_active}
              onClick={onClick}
            >
              {button.name}
            </NavLink>
          ))
        }
      </div>
    </div>
  )
}

export { MobileMenu as default }