import React from "react"

// Basic Footer Data

const type = "center"
const description = (
  <>
    Hauptstraße 29 - 87490 Haldenwang <br />
    <br /> +49 (0)8374 / 586200 <br /> info@naturecke-haldenwang.de
  </>
)
const copyright = "Naturecke Haldenwang"

// Section One - Custom Values
const sectionOne = [
  {
    name: "Wir stellen ein:",
  },
  {
    name: "Servicetechniker (m/w/d)",
    to: "/Karriere",
  },
]

// Section Two - Custom Values
const sectionTwo = [
  {
    name: "Kontakt",
  },
  {
    name: "Werktags von 8 - 18 Uhr",
  },
  {
    name: "+49 (0)8374 / 2415468",
  },
  {
    name: "tobias@prueftechnik-zierof.de",
  },
]

// Social Media - Custom Values
const socialMedia = null
// [
//   {
//     name: 'Social Media'
//   }, {
//     name: 'Facebook',
//     to: '/'
//   }, {
//     name: 'Linkedin',
//     to: '/impressum'
//   }
// ]

// Legal - Fixed Values
const legal = [
  {
    name: "Rechtliches",
  },
  {
    name: "Datenschutz",
    to: "/datenschutz",
  },
  {
    name: "Impressum",
    to: "/impressum",
  },
]

export {
  type,
  description,
  copyright,
  sectionOne,
  sectionTwo,
  legal,
  socialMedia,
}
