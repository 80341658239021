import './index.scss'
import 'typeface-open-sans'
import 'typeface-permanent-marker'
import 'typeface-quicksand'

import * as serviceWorker from './serviceWorker'

import App from './App'
import { Provider } from "mobx-react"
import React from 'react'
import ReactDOM from 'react-dom'
import store from './stores/store'

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
