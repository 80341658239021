import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Imprint as ImprintContainer } from 'containers'
import { helmet } from 'utils';
import styles from './Imprint.module.scss'

const SEO_Data = helmet({
  title: 'Impressum',
  description: 'In unserem Impressum finden Sie alle Informationen über die Naturecke Haldenwang'
})

const Imprint = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <ImprintContainer />
      </article>
      <Footer />
    </div>
  )
}

export { Imprint as default }